<template>
  <div>
    <div
      class="legal-header "
    >
      <div>
        <b-link
          class="navbar-brand"
          :to="`${user ? '/' : '/'}`"
        >
          <span class="brand-logo">
            <b-img
              :src="appLongLogo"
              alt="logo"
              class="long-logo"
            />
          </span>
        </b-link>
      </div>
      <div
        v-if="!user"
        class="header-buttons"
      >
        <b-link
          to="/login"
          class="btn btn-outline-primary"
        >
          Log In
        </b-link>
        <b-link
          to="/register"
          class="btn btn-primary"
        >
          Sign Up
        </b-link>
      </div>
      <div v-else>
        <b-link
          to="/"
          class="btn btn-outline-primary"
        >
          Back To Dashboard
        </b-link>
      </div>
    </div>
    <b-row>
      <div class="legal-body">
        <div class="legal-breadcrumbs">
          <b-breadcrumb>
            <b-breadcrumb-item
              to="/"
            >
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
                color="#F0554E"
              />
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :active="true"
              to="/"
            >
              Legal Notices
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <b-img
          fluid
          :src="imgUrl"
          alt="legal banner"
          class="legal-banner"
        />
        <div class="legal-info">
          <h3 v-if="lastUpdated">
            Last updated: {{ lastUpdated }}
          </h3>
          <h1>Legal Notices</h1>
          <p>Welcome to our 'Legal Notices' page, dedicated to providing you with clear and essential information regarding our software's End User License Agreement (EULA). As a user, you are required to accept our EULA upon your first login, granting you access to our platform. Periodically, we update our EULA, so sometimes you will be prompted to review and accept the latest terms.</p>
        </div>
        <div class="legal-list">
          <h3>Latest EULA Versions:</h3>
          <ul>
            <li
              v-for="(eula) in eulas"
              :key="eula.url"
            >
              <b-link
                :href="eula.url"
                target="_blank"
              >
                Legal/EULA/{{ eula.date }}
              </b-link>
            </li>
          </ul>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>

import {
  BLink, BImg, BRow, BBreadcrumb, BBreadcrumbItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { $themeConfig } from '@themeConfig'
import store from '@/store/index'
import axios from '../axios-resolver'

export default {
  directives: {
    Ripple,
  },
  components: {
    BLink,
    BImg,
    BRow,
    BBreadcrumb,
    BBreadcrumbItem,
  },
  data() {
    return {
      isLogged: false,
      // eslint-disable-next-line global-require
      sideImg: require('@/assets/images/pages/legal.svg'),
      user: null,
      eulas: [],
      lastUpdated: '',
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties, global-require
        this.sideImg = require('@/assets/images/pages/legal.svg')
        console.log(this.sideImg)
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('userData'))
    this.fetchEULAs()
  },
  setup() {
    const { appLongLogo } = $themeConfig.app

    return {
      appLongLogo,
    }
  },
  methods: {
    async fetchEULAs() {
      const { data: { data } } = await axios.get('/eula/all')

      if (this.$route.name === 'latest') {
        window.location.replace(data[0].url)
      }

      this.eulas = data.map(eula => ({
        date: this.formatDate(eula.date),
        test: 1,
        url: eula.url,
      }))

      this.handleLastUpdated(data[0])
    },
    formatDate(date) {
      const formatedDate = new Date(date)
      const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

      const month = formatedDate.getMonth()
      const year = formatedDate.getFullYear()

      return months[month] + year.toString().slice(-2)
    },
    handleLastUpdated(rawDate) {
      const date = new Date(rawDate.date)

      const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ]

      const month = months[date.getMonth()]
      const day = date.getDate()
      const year = date.getFullYear()

      let suffix = 'th'
      if (day % 10 === 1 && day !== 11) {
        suffix = 'st'
      } else if (day % 10 === 2 && day !== 12) {
        suffix = 'nd'
      } else if (day % 10 === 3 && day !== 13) {
        suffix = 'rd'
      }

      this.lastUpdated = `${month} ${day}${suffix}, ${year}`
    },
  },
}
</script>

<style lang="scss">
</style>
